<template>
  <div>
<!--    <div v-if="!driverData.cnh_doc" class="alert alert-danger" role="alert">-->
<!--      {{ $i18n.t('modalBooking.alerts.noDriverLicenseDoc') }}-->
<!--    </div>-->
    <div v-if="!driverData.license_number" class="alert alert-danger" role="alert">
      ❌ Dados incompletos: adicionar o número da CNH
    </div>
    <div v-if="isDriverLicenseExpired()" class="alert alert-danger" role="alert">
      {{ $i18n.t('modalBooking.alerts.driverLicenseExpired') }}
    </div>
<!--    <div v-else class="alert alert-success" role="alert">-->
<!--      ✅ Dados do motorista preenchidos-->
<!--    </div>-->
  </div>
</template>

<script>
import DRIVER_GET from '@graphql/driver/queries/get.gql';
export default {
  props: {
    license_expired_at: {
      type: String,
      default: '',
    },
    drive_id: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      driverData: null,
    }
  },
  apollo: {
    driver: {
      query: DRIVER_GET,
      variables() {
        return {
          id: this.driverId || this.$route.params.id,
        };
      },
      result(data) {
        if (data.data) this.driverData = data.data.driver;
      },
    },
  },
  methods: {
    isDriverLicenseExpired() {
      const driverLicenseExpiredAt = new Date(this.license_expired_at || this.driverData.license_expired_at);
      const today = new Date();
      const thirtyDaysFromNow = new Date();
      thirtyDaysFromNow.setDate(today.getDate() - 31);
      return driverLicenseExpiredAt <= thirtyDaysFromNow;
    },
  }
}
</script>
