var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "driverPendingBookingInfo",
      attrs: {
        id: "fleetCompareSignatures",
        lazy: "",
        "hide-footer": "",
        size: "lg",
        title: "Comparar Assinaturas",
        "hide-header-close": !_vm.blockByPermission,
        "no-close-on-esc": !_vm.blockByPermission,
        "no-close-on-backdrop": !_vm.blockByPermission,
      },
      on: {
        "before-open": _vm.handleSignatureCompare,
        show: _vm.handleOpen,
        hide: _vm.handleClose,
        close: _vm.handleClose,
      },
    },
    [
      !_vm.documentAlreadyMatched
        ? [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "text-center mb-3", attrs: { md: "12" } },
                  [
                    _c("h3", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        " As assinaturas dos documentos estão parecidas? "
                      ),
                    ]),
                    _c(
                      "b-row",
                      [
                        _vm.cleanContractUrl
                          ? _c("b-col", { staticClass: "text-center mt-3" }, [
                              !_vm.loading
                                ? _c(
                                    "div",
                                    { staticClass: "image-container" },
                                    [
                                      _c("b-img", {
                                        attrs: {
                                          src: _vm.images.contractSignature,
                                          alt: "Contract Image",
                                          title: "Abrir documento",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openFullscreen(
                                              _vm.cleanContractUrl
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "top-right-button" },
                                        [
                                          _c("img", {
                                            staticClass: "fullscreen-icon",
                                            attrs: {
                                              src: _vm.fullScreenIcon,
                                              alt: "Fullscreen Icon",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openFullscreen(
                                                  _vm.cleanContractUrl
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center text-primary my-2",
                                    },
                                    [
                                      _c("b-spinner", {
                                        staticClass: "align-middle mr-2",
                                      }),
                                      _c("strong", [_vm._v("Carregando...")]),
                                    ],
                                    1
                                  ),
                            ])
                          : _vm._e(),
                        _vm.cleanDriverDocumentUrl
                          ? _c("b-col", { staticClass: "text-center mt-3" }, [
                              !_vm.loading
                                ? _c(
                                    "div",
                                    { staticClass: "image-container" },
                                    [
                                      _c("b-img", {
                                        attrs: {
                                          src: _vm.images
                                            .driverDocumentSignature,
                                          alt: "Driver License Image",
                                          title: "Abrir documento",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openFullscreen(
                                              _vm.cleanDriverDocumentUrl
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "top-right-button" },
                                        [
                                          _c("img", {
                                            staticClass: "fullscreen-icon",
                                            attrs: {
                                              src: _vm.fullScreenIcon,
                                              alt: "Fullscreen Icon",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openFullscreen(
                                                  _vm.cleanDriverDocumentUrl
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center text-primary my-2",
                                    },
                                    [
                                      _c("b-spinner", {
                                        staticClass: "align-middle mr-2",
                                      }),
                                      _c("strong", [_vm._v("Carregando...")]),
                                    ],
                                    1
                                  ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "text-center mt-2 mb-4 text-danger" },
                  [
                    !_vm.blockByPermission
                      ? _c("span", [
                          _c("b", [_vm._v("Obs: ")]),
                          _vm._v(
                            "Se a assinatura no contrato ou na CNH não estiver corretamente posicionada, clique na "
                          ),
                          _c("b", [_vm._v('"lupa"')]),
                          _vm._v(
                            " no canto superior direito e verifique se as assinaturas são iguais. "
                          ),
                        ])
                      : _c("span", [
                          _c("b", [_vm._v("ATENÇÃO ")]),
                          _c("br"),
                          _vm._v(
                            " Voce não tem permissão para avaliar estes documentos."
                          ),
                          _c("br"),
                          _c(
                            "b",
                            [
                              _vm._v(
                                "Por favor, envie a url abaixo para o seu "
                              ),
                              _c(
                                "b-badge",
                                {
                                  staticStyle: { "font-size": "16px" },
                                  attrs: {
                                    variant: _vm.permission.includes(
                                      "ACL:DOC:APPROVAL_MANAGER"
                                    )
                                      ? "primary"
                                      : "warning",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.permission.includes(
                                          "ACL:DOC:APPROVAL_MANAGER"
                                        )
                                          ? "supervisor"
                                          : "gestor"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "url mx-auto",
                              attrs: { title: "Copiar url" },
                              on: { click: _vm.copyToClipboard },
                            },
                            [
                              _c("i", { staticClass: "fa fa-copy mr-2" }),
                              _vm._v(" " + _vm._s(_vm.getUrl) + " "),
                            ]
                          ),
                        ]),
                  ]
                ),
              ],
              1
            ),
            !_vm.blockByPermission
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "text-center mt-2 mb-4" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "custom-checkbox",
                            attrs: { id: "checkbox", name: "checkbox-1" },
                            model: {
                              value: _vm.isAccepted,
                              callback: function ($$v) {
                                _vm.isAccepted = $$v
                              },
                              expression: "isAccepted",
                            },
                          },
                          [_vm._v(" Eu aceito que as assinaturas são iguais ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("hr"),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "text-center mb-12 button-list" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "font-weight-bold btn-gray",
                        attrs: {
                          id: "driver-view-activate-booking",
                          variant: "dark",
                          title:
                            _vm.blockByPermission &&
                            "Você não tem permissão para rejeitar as assinaturas.",
                          disabled:
                            !_vm.isImagesLoaded || _vm.blockByPermission,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submit(false, $event)
                          },
                        },
                      },
                      [_vm._v(" Não ")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          id: "driver-view-activate-booking",
                          variant: "success",
                          title:
                            _vm.blockByPermission &&
                            "Você não tem permissão para aceitar as assinaturas.",
                          disabled:
                            !_vm.isAccepted ||
                            !_vm.isImagesLoaded ||
                            _vm.blockByPermission,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submit(true, $event)
                          },
                        },
                      },
                      [_vm._v(" Sim ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "text-center mt-2 mb-4" },
                  [
                    _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
                      _c("h5", [
                        _c("strong", [_vm._v("Atenção!")]),
                        _c("br"),
                        _vm._v(" Este documento já foi verificado"),
                        _c("br"),
                        _c("strong", [_vm._v("Suba um documento diferente")]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("hr"),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "text-center mb-1" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          id: "driver-view-cancel-booking",
                          variant: "danger",
                        },
                        on: { click: () => _vm.handleClose(true) },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("modalBooking.words.close")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }