<template>
  <b-modal
    id="fleetCompareSignatures"
    ref="driverPendingBookingInfo"
    lazy
    hide-footer
    size="lg"
    title="Comparar Assinaturas"
    :hide-header-close="!blockByPermission"
    :no-close-on-esc="!blockByPermission"
    :no-close-on-backdrop="!blockByPermission"
    @before-open="handleSignatureCompare"
    @show="handleOpen"
    @hide="handleClose"
    @close="handleClose"
  >
    <template v-if="!documentAlreadyMatched">
      <b-row>
        <b-col md="12" class="text-center mb-3">
          <h3 class="font-weight-bold">
            As assinaturas dos documentos estão parecidas?
          </h3>
          <b-row>
            <b-col v-if="cleanContractUrl" class="text-center mt-3">
              <div v-if="!loading" class="image-container">
                <b-img
                  :src="images.contractSignature"
                  alt="Contract Image"
                  title="Abrir documento"
                  @click="openFullscreen(cleanContractUrl)"
                />
                <div class="top-right-button">
                  <img
                    :src="fullScreenIcon"
                    alt="Fullscreen Icon"
                    @click="openFullscreen(cleanContractUrl)"
                    class="fullscreen-icon"
                  />
                </div>
              </div>
              <div v-else class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2" />
                <strong>Carregando...</strong>
              </div>
            </b-col>
            <b-col v-if="cleanDriverDocumentUrl" class="text-center mt-3">
              <div v-if="!loading" class="image-container">
                <b-img
                  :src="images.driverDocumentSignature"
                  alt="Driver License Image"
                  title="Abrir documento"
                  @click="openFullscreen(cleanDriverDocumentUrl)"
                />
                <div class="top-right-button">
                  <img
                    :src="fullScreenIcon"
                    alt="Fullscreen Icon"
                    @click="openFullscreen(cleanDriverDocumentUrl)"
                    class="fullscreen-icon"
                  />
                </div>
              </div>
              <div v-else class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2" />
                <strong>Carregando...</strong>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center mt-2 mb-4 text-danger">
          <span v-if="!blockByPermission">
            <b>Obs: </b>Se a assinatura no contrato ou na CNH não estiver
            corretamente posicionada, clique na <b>"lupa"</b> no canto superior
            direito e verifique se as assinaturas são iguais.
          </span>
          <span v-else>
            <b>ATENÇÃO </b><br>
            Voce não tem permissão para avaliar estes documentos.<br>
            <b>Por favor, envie a url abaixo para o seu
              <b-badge :variant="permission.includes('ACL:DOC:APPROVAL_MANAGER') ? 'primary' : 'warning'" style="font-size: 16px">
                {{ permission.includes('ACL:DOC:APPROVAL_MANAGER') ? 'supervisor' : 'gestor' }}
              </b-badge>
            </b>
            <div
              class="url mx-auto"
              title="Copiar url"
              @click="copyToClipboard"
            >
              <i class="fa fa-copy mr-2" />
              {{ getUrl }}
            </div>
          </span>
        </b-col>
      </b-row>
      <b-row v-if="!blockByPermission">
        <b-col class="text-center mt-2 mb-4">
          <b-form-checkbox
            id="checkbox"
            v-model="isAccepted"
            name="checkbox-1"
            class="custom-checkbox"
          >
            Eu aceito que as assinaturas são iguais
          </b-form-checkbox>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="text-center mb-12 button-list">
          <b-button
            id="driver-view-activate-booking"
            class="font-weight-bold btn-gray"
            variant="dark"
            :title="blockByPermission && 'Você não tem permissão para rejeitar as assinaturas.'"
            :disabled="!isImagesLoaded || blockByPermission"
            @click="submit(false, $event)"
          >
            Não
          </b-button>

          <b-button
            id="driver-view-activate-booking"
            class="font-weight-bold"
            variant="success"
            :title="blockByPermission && 'Você não tem permissão para aceitar as assinaturas.'"
            :disabled="!isAccepted || !isImagesLoaded || blockByPermission"
            @click="submit(true, $event)"
          >
            Sim
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col class="text-center mt-2 mb-4">
          <b-alert
            show
            variant="warning"
          >
            <h5>
              <strong>Atenção!</strong><br>
              Este documento já foi verificado<br>
              <strong>Suba um documento diferente</strong>
            </h5>
          </b-alert>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col class="text-center mb-1">
          <b-button
            id="driver-view-cancel-booking"
            class="font-weight-bold"
            variant="danger"
            @click="() => handleClose(true)"
          >
            {{ $t('modalBooking.words.close') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import UPDATE_BOOKING_CONTRACT from '@graphql/driver-documents/mutations/update-booking-contract.gql';
import { isBrazil } from '@utils/helper-region';
import { format } from 'date-fns';

export default {
  name: 'ModalFleetCompareSignatures',
  props: {
    driverDocumentUrl: {
      type: String,
      required: false,
      default: null,
    },
    contractUrl: {
      type: String,
      required: false,
      default: null,
    },
    bookingId: {
      type: String,
      required: true,
    },
    driverId: {
      type: String,
      required: false,
    },
    handleYes: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    initialContractSignature: {
      type: String,
      required: false,
      default: null,
    },
    initialDriverDocumentSignature: {
      type: String,
      required: false,
      default: null,
    },
    userEmail: {
      type: String,
      required: false,
    },
    canOpenModalOnLoad: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    isAccepted: false,
    isImagesLoaded: false,
    images: {
      contractSignature: null,
      driverDocumentSignature: null,
      contractUrl: null,
      driverDocumentURL: null,
    },
    answer: null,
    fullScreenIcon: '/img/fleet-tracking/full-screen-icon.png',
    documentAlreadyMatched: false,
    blockByPermission: false,
    permission: []
  }),

  computed: {
    getUrl() {
      const { contract } = this.$route?.params;
      return window.location.origin + this.$route.fullPath + (contract ? '' : `/${this.bookingId}`)
    },
    cleanContractUrl() {
      return this.contractUrl ? new URL(this.contractUrl).origin + new URL(this.contractUrl).pathname : null;
    },
    cleanDriverDocumentUrl() {
      return this.driverDocumentUrl ? new URL(this.driverDocumentUrl).origin + new URL(this.driverDocumentUrl).pathname : null;
    },
    loading() {
      return !this.images.contractSignature || !this.images.driverDocumentSignature;
    },
  },

  mounted() {
    this.isAccepted = false;
    this.initializeImages();
    const { contract } = this.$route?.params;
    if (contract) {
      this.pollSignatureJobStatus(contract);
    }
    if (this.canOpenModalOnLoad) {
      this.openModalOnLoad();
    }
  },

  beforeDestroy() {
    this.$root.$emit('bv::hide::modal', 'fleetCompareSignatures');
  },

  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.getUrl)
      this.$swal({
        type: 'success',
        title: 'Link copiado!',
        timer: 2000,
      });
    },
    initializeImages() {
      this.isImagesLoaded = !!(this.images.contractSignature && this.images.driverDocumentSignature)
    },
    handleOpen() {
      this.initializeImages();
    },
    openModalOnLoad() {
      this.handleSignatureCompare();
      this.$refs.fleetCompareSignatures.show();
    },
    handleClose(loadingPage = false) {
      const { contract } = this.$route?.params;
      if (contract) {
        return this.$router.push({ path: this.$route.fullPath.replace(`/${contract}`, '') });
      }
      if (loadingPage === true) {
        window.location.reload();
      }
      this.$root.$emit('bv::hide::modal', 'fleetCompareSignatures');
    },
    async handleSignatureCompare(imageDefault = null, documentType = null) {
      const groups = this.$store.getters['user/groups'];

      this.clear();

      if (!isBrazil()) return;

      if (!this.cleanContractUrl || !this.cleanDriverDocumentUrl) return;

      const payload = {
        bookingId: this.bookingId,
        contractUrl: this.cleanContractUrl,
        driverLicenseUrl: this.cleanDriverDocumentUrl,
        driverId: this.driverId
      };

      try {
        const response = await this.$fleetRegulationProvider.post('/signature/compare', payload)

        if (response.status === 201) {
            const jobId = response.data.jobId
            const currentDate = new Date()
            currentDate.setHours(currentDate.getHours() + 3);
            const formattedDate = format(currentDate, 'YYYY-MM-DD HH:mm:ss');

            await this.pollSignatureJobStatus(jobId, formattedDate)
            return
        }

        if (response.status === 200) {
          if (!response.data.isMatch) {
            this.images.contractSignature = response.data.image.document || response.data.contractUrl
            this.images.driverDocumentSignature = response.data.image.cnh || response.data.driverDocumentUrl
            this.images.contractUrl = response.data.contractUrl
            this.images.driverDocumentURL = response.data.driverDocumentUrl
            this.isImagesLoaded = true
            this.blockByPermission = !response?.data?.permissions?.some(permission => groups.includes(permission));
            this.permission = response?.data?.permissions
            if (response?.data?.error) {
              this.blockByPermission = !groups.includes('ACL:DOC:RESTRICTED_MANAGER')
            }
            this.$root.$emit('signatureMatched', false)
          } else {
            this.$root.$emit('signatureMatched', true)
          }
        }
      } catch (error) {
        console.error('Error getting images:', error);
        this.images.contractSignature = this.cleanContractUrl
        this.images.driverDocumentSignature = this.cleanDriverDocumentUrl
        this.isImagesLoaded = true;
        this.blockByPermission = !groups.includes('ACL:DOC:RESTRICTED_MANAGER')
        this.permission = ['ACL:DOC:RESTRICTED_MANAGER']
        if (!error?.response?.data?.isMatch && error?.response?.status === 400) {
          this.documentAlreadyMatched = true
          if (documentType === 'contrato') {
            this.images.contractSignature = imageDefault
          }
          if (documentType === 'cnh') {
            this.images.driverDocumentSignature = imageDefault
          }
          await this.updateDocumentUrl(documentType, imageDefault)
        }
        this.$root.$emit('signatureMatched', false)
      }
    },
    async pollSignatureJobStatus(jobId, date, retryCount = 0) {
      try {
        const groups = this.$store.getters['user/groups'];
        const response = await this.$fleetRegulationProvider.get(`/signature/jobs/${jobId}/status`, {
          params: {
            createdAt: date,
          }
        })

        if (response.data.status === 'comparison_success') {
          if (!response.data.isMatch) {
            this.images.contractSignature = response.data.image.document || response.data.contractUrl
            this.images.driverDocumentSignature = response.data.image.cnh || response.data.driverDocumentUrl
            this.images.contractUrl = response.data.contractUrl
            this.images.driverDocumentURL = response.data.driverDocumentUrl
            this.isImagesLoaded = true
            this.documentAlreadyMatched = false
            this.blockByPermission = !response?.data?.permissions?.some(permission => groups.includes(permission));
            this.permission = response?.data?.permissions
            if (response?.data?.error) {
              this.blockByPermission = !groups.includes('ACL:DOC:RESTRICTED_MANAGER')
            }
            this.$root.$emit('signatureMatched', false)
          } else {
            this.$root.$emit('signatureMatched', true)
          }
        } else if (retryCount < 20 && response.data.status !== 'comparison_failed') {
          setTimeout(() => {
            this.pollSignatureJobStatus(jobId, date, retryCount + 1)
          }, 3000)
        } else {
          this.images.contractSignature = this.cleanContractUrl
          this.images.driverDocumentSignature = this.driverDocumentUrl
          this.isImagesLoaded = false;
          this.blockByPermission = !groups.includes('ACL:DOC:RESTRICTED_MANAGER')
          this.permission = ['ACL:DOC:RESTRICTED_MANAGER']
          this.$root.$emit('signatureMatched', false)
        }
      } catch (error) {
        console.error('Error polling signature job status:', error)
      }
    },

    async updateDocumentUrl(documentType, url) {
      let MUTATION
      let VARIABLES
      if (documentType === 'contrato') {
        MUTATION = UPDATE_BOOKING_CONTRACT;
        VARIABLES = {
          input: {
            booking: this.bookingId,
            contract: url,
          },
        };
      }

      if (documentType === 'cnh') {
        return await this.$fleetRegulationProvider.put('/drivers/document/rental', {
          driverId: this.driverId,
          document: url,
        })
      }

      if (MUTATION !== UPDATE_BOOKING_CONTRACT) return;

      return this.$apollo
        .mutate({
          mutation: MUTATION,
          variables: VARIABLES,
        })
        .then((res) => {
          console.log(res)
        }).catch(err => {
          console.error('Error updating document url:', err);
        })
    },

    openFullscreen(url) {

      const image = [url]

      this.$viewerApi({
        options: {
          navbar: false,
          scalable: false,
          keyboard: false,
        },
        images: image
      })
    },
    async submit(answer, event) {
      event.preventDefault()
      this.$root.$emit('signatureMatched', answer)
      this.answer = answer
      const koviAdminEmail = JSON.parse(localStorage.getItem('kovi-admin')).user.attributes.email

      try {
        await this.$fleetRegulationProvider.post('/signature/match', {
          bookingId: this.bookingId,
          contractUrl: this.cleanContractUrl,
          driverLicenseUrl: this.images?.driverDocumentURL || this.cleanDriverDocumentUrl,
          answer: this.answer,
          userEmail: koviAdminEmail
        });
      } catch (error) {
        console.error('Error submitting signature match:', error);
      }

      if (answer && this.handleYes) await this.handleYes(event);

      this.handleClose();
    },
    clear() {
      this.isAccepted = false
      this.isImagesLoaded = false
      this.images = {
        contractSignature: null,
        driverDocumentSignature: null,
      }
      this.answer = null
    }
  }
};
</script>

<style scoped>
.button-list {
  margin-top: 8px;
  margin-bottom: 8px;
}

::v-deep .button-list button:nth-child(2) {
  margin-left: 24px;
}

.btn-gray {
  color: #23282c;
  background-color: #c8ced3;
  border-color: #c8ced3;
}

.btn-gray:hover {
  color: #23282c;
  background-color: #acb5bc;
  border-color: #a5aeb7;
}

img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  height: 200px;
  border: solid 1px #a5aeb747;
  border-radius: 5px;
  cursor: pointer;
}

.image-container {
  position: relative;
  display: inline-block;
}

.top-right-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.fullscreen-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  filter: drop-shadow(0 0 2px black);
  background-color: white;
  border-radius: 25%;
  padding: 4px;
  transition: transform 0.3s ease-in-out;
}

.fullscreen-icon:hover {
  transform: scale(1.2); /* Scales up the icon on hover */
}

.url {
  font-size: 16px;
  color: white;
  margin: 20px 0;
  padding: 12px 20px;
  width: 100%;
  max-width: 600px;
  border: 1px solid #4dbd74;
  border-radius: 8px;
  background-color: #4dbd74;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

/* Adiciona um efeito visual ao passar o mouse */
.url:hover {
  border-color: #4dbd74;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  background-color: #4dbd74bd;
}

/* Adaptação responsiva para telas menores */
@media (max-width: 600px) {
  .url {
    width: 100%;
  }
}
</style>
