var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.driverData.license_number
      ? _c(
          "div",
          { staticClass: "alert alert-danger", attrs: { role: "alert" } },
          [_vm._v(" ❌ Dados incompletos: adicionar o número da CNH ")]
        )
      : _vm._e(),
    _vm.isDriverLicenseExpired()
      ? _c(
          "div",
          { staticClass: "alert alert-danger", attrs: { role: "alert" } },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$i18n.t("modalBooking.alerts.driverLicenseExpired")
                ) +
                " "
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }